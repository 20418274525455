@import './yeti_vars';

@import '../styles/ibe';

// Yeti 5.3.3
// Bootswatch

.navbar {
	font-weight: $headings-font-weight;
}

.bg-primary {
	.dropdown-menu {
		background-color: $primary;

		.dropdown-item,
		.dropdown-item:focus {
			color: $navbar-dark-color;
		}

		.dropdown-item.active,
		.dropdown-item:hover,
		.dropdown-item:focus {
			color: $white;
			background-color: shade-color($primary, 10%);
		}
	}
}

.bg-dark {
	background-color: $gray-800 !important;

	.dropdown-menu {
		background-color: $gray-800;

		.dropdown-item,
		.dropdown-item:focus {
			color: $navbar-dark-color;
		}

		.dropdown-item.active,
		.dropdown-item:hover,
		.dropdown-item:focus {
			color: $white;
			background-color: shade-color($gray-800, 10%);
		}
	}
}

.bg-light {
	.dropdown-menu {
		background-color: $gray-200;

		.dropdown-item,
		.dropdown-item:focus {
			color: $navbar-light-color;
		}

		.dropdown-item.active,
		.dropdown-item:hover,
		.dropdown-item:focus {
			color: $navbar-light-hover-color;
			background-color: tint-color($gray-200, 10%);
		}
	}
}

// Buttons
.btn {
	&-primary {
		border-color: shade-color($primary, 10%);
	}

	&-secondary {
		border-color: shade-color($secondary, 10%);
	}

	&-success {
		border-color: shade-color($success, 10%);
	}

	&-info {
		color: $white;
		border-color: shade-color($info, 10%);
	}

	&-danger {
		border-color: shade-color($danger, 10%);
	}

	&-warning {
		color: $white;
		border-color: shade-color($warning, 10%);
	}

	&-light {
		border-color: shade-color($light, 10%);
	}

	&-dark {
		border-color: shade-color($dark, 10%);
	}
}

.btn-outline-secondary {
	color: var(--bs-primary-color);
}

.btn-group {
	.dropdown-menu {
		border-top-width: 0;
	}

	.dropdown-toggle {
		&.btn-primary~.dropdown-menu {
			background-color: $primary;
			border-color: shade-color($primary, 10%);

			.dropdown-item {
				color: $white;
			}

			.dropdown-item:hover,
			.dropdown-item:focus {
				background-color: shade-color($primary, 16%);
			}
		}

		&.btn-secondary~.dropdown-menu {
			background-color: $secondary;
			border-color: shade-color($secondary, 10%);

			.dropdown-item {
				color: $body-color;
			}

			.dropdown-item:hover,
			.dropdown-item:focus {
				background-color: shade-color($secondary, 16%);
			}
		}

		&.btn-success~.dropdown-menu {
			background-color: $success;
			border-color: shade-color($success, 10%);

			.dropdown-item {
				color: $white;
			}

			.dropdown-item:hover,
			.dropdown-item:focus {
				background-color: shade-color($success, 16%);
			}
		}

		&.btn-info~.dropdown-menu {
			background-color: $info;
			border-color: shade-color($info, 10%);

			.dropdown-item {
				color: $white;
			}

			.dropdown-item:hover,
			.dropdown-item:focus {
				background-color: shade-color($info, 16%);
			}
		}

		&.btn-warning~.dropdown-menu {
			background-color: $warning;
			border-color: shade-color($warning, 10%);

			.dropdown-item {
				color: $white;
			}

			.dropdown-item:hover,
			.dropdown-item:focus {
				background-color: shade-color($warning, 16%);
			}
		}

		&.btn-danger~.dropdown-menu {
			background-color: $danger;
			border-color: shade-color($danger, 10%);

			.dropdown-item {
				color: $white;
			}

			.dropdown-item:hover,
			.dropdown-item:focus {
				background-color: shade-color($danger, 16%);
			}
		}
	}
}

// Typography
.text-secondary {
	color: $gray-700 !important;
}

.blockquote-footer {
	color: $gray-600;
}

// Tables
table {
	font-size: $font-size-sm;
}

// Forms
label,
.control-label,
.help-block,
.checkbox,
.radio,
.form-control-feedback {
	font-size: $font-size-sm;
}

// Navs
.dropdown-item {
	padding-top: .75rem;
	padding-bottom: .75rem;
	font-size: $font-size-sm;
	font-weight: 300;
}

.nav-pills {
	.active {
		border: 1px solid shade-color($primary, 10%);
	}
}

.breadcrumb {
	font-size: $font-size-sm;
	font-weight: 300;
	text-transform: uppercase;
	border: 1px solid $dropdown-border-color;
}

.pagination {
	font-weight: 300;

	.page-link {
		margin-right: .1em;
		margin-left: .1em;
		border-color: transparent;
		border-radius: 3px;

		&:hover,
		&:focus {
			text-decoration: none;
		}
	}

	.page-item.disabled {
		.page-link {
			border-color: transparent;
		}
	}
}

.pagination,
.pagination-lg,
.pagination-sm {

	.page-item:first-child,
	.page-item:last-child {
		.page-link {
			border-radius: 3px;
		}
	}
}

@include color-mode(dark) {
	.pagination {
		--bs-pagination-disabled-color: #{$gray-700};
	}
}

.list-group {
	font-size: $font-size-sm;
	font-weight: 300;
}

.list-group-item.disabled,
.list-group-item:disabled {
	background-color: var(--bs-secondary-bg);
}

// Indicators

.alert {
	font-size: $font-size-sm;
	font-weight: 300;

	.alert-link {
		text-decoration: underline;
	}
}

.badge {

	&.bg-secondary,
	&.bg-light {
		color: $dark;
	}
}

// Progress bars
.progress[value] {
	height: 22px;
	padding: 2px;
	background-color: #f6f6f6;
	border: 1px solid #ccc;
	@include box-shadow(none);
}

// Popovers
.popover-header {
	border-top-left-radius: 0;
	border-top-right-radius: 0;
}